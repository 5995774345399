import axios from "axios";
import CryptoJS from "crypto-js";

const actionHandler = (payload: any) => {

  //Headers
  axios.defaults.headers["Content-Type"] = "application/json";
  axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers["Strict-Transport-Security"] = "max-age=3600 ; includeSubDomains";

  //Token for authentication
  const token = localStorage.getItem("admin-token");
  // const ApiKey = process.env.REACT_APP_API_KEY;
  
  if (token) {
    var bytes = CryptoJS.AES.decrypt(token, process.env.REACT_APP_ENCRYPTION_KEY);
    var decryptedDbName = bytes.toString(CryptoJS.enc.Utf8);
    axios.defaults.headers.common["dbName"] = decryptedDbName;
    // axios.defaults.headers.common["api_key"] =ApiKey;
  }

  if (payload.url.includes("http")) {
    payload.baseURL = "";
  }
  else {
    payload.baseURL = process.env.REACT_APP_API_URL;
  }

  // Determine API key based on the URL
  let apiKey;
  if (payload.url.includes(process.env.REACT_APP_API_URL) || payload.baseURL.includes(process.env.REACT_APP_API_URL)) {
    apiKey = process.env.REACT_APP_Report_api_key;
  } else if (payload.url.includes(process.env.REACT_APP_ACCOUNTS_API_URL) || payload.baseURL.includes(process.env.REACT_APP_ACCOUNTS_API_URL)) {
    apiKey = process.env.REACT_APP_Account_api_key;
  } else if (payload.url.includes(process.env.REACT_APP_CURRENT_COMPANY_API_URL) || payload.baseURL.includes(process.env.REACT_APP_CURRENT_COMPANY_API_URL)) {
    apiKey = process.env.REACT_APP_Company_api_key;
  } else if (payload.url.includes(process.env.REACT_APP_INVENTORY_API_URL) || payload.baseURL.includes(process.env.REACT_APP_INVENTORY_API_URL)) {
    apiKey = process.env.REACT_APP_Inventory_api_key;
  } else if (payload.url.includes(process.env.REACT_APP_LOGIN_URL) || payload.baseURL.includes(process.env.REACT_APP_LOGIN_URL)) {
    apiKey = process.env.REACT_APP_AppControl_api_key;
  } else if (payload.url.includes(process.env.REACT_APP_ADMIN_URL) || payload.baseURL.includes(process.env.REACT_APP_ADMIN_URL)) {
    apiKey = process.env.REACT_APP_AppControl_api_key;
  }

  // Set the API key in the headers
  if (apiKey) {
    axios.defaults.headers.common["api-key"] = apiKey;
  }

  return new Promise((resolve, reject) => {
    axios(payload).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        resolve(response);
      } else {
        reject(response);
      }
    }).catch((err) => {
      reject(err);
    });
  });
};
axios.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode == undefined) {
    // Server needs to specify CORS headers in the response
    // Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
    // Otherwise, these kinda issues happen
    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }
  return new Promise(() => {
    if (statusCode == 401 && err.config && !err.config.__isRetryRequest) {
      // Got an unauthorized, logout the user
      localStorage.clear();
      delete axios.defaults.headers.common.Authorization;
      window.location.pathname = "/auth/sign-in";
    }

    throw err;
  });
});

export default {
  //Dashboard 
  dashboardTop10DataURL:'Dashboard?branchId={branchId}&companyId={companyId}&searchType=Top10Data&userId={userId}',
  dashboardGraphDataURL:'Dashboard?branchId={branchId}&companyId={companyId}&searchType=GraphData&startDate={startDate}&endDate={endDate}&userId={userId}',
  dashboardBetweenDateDataURL:'Dashboard?branchId={branchId}&companyId={companyId}&searchType=TotalBetweenDate&startDate={startDate}&endDate={endDate}&userId={userId}',
  dashboardMonthWiseTotalDataURL:"Dashboard?branchId={branchId}&companyId={companyId}&searchType=MonthWiseTotal&startDate={startDate}&endDate={endDate}&userId={userId}",
  dashboardTillEndDateDataURL:'Dashboard?branchId={branchId}&companyId={companyId}&searchType=TillEndDate&endDate={endDate}&userId={userId}',
  dailyCashFlowURL:"Dashboard?branchId={branchId}&companyId={companyId}&searchType=CashFlow&startDate={startDate}&endDate={endDate}&userId={userId}",
  incomeAndExpenseReportListURL:'Dashboard?searchType=IncomeExpense&searchQuery=Graph&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}',
  stockSummaryURL:'Dashboard?searchType=StockValue&searchQuery=&sortType=&desc&pageNo=&startDate=&endDate=&branchId={branchId}&companyId={companyId}',
  last5ReceiptPaymentDataURL:'Dashboard?searchType=Last5Data&branchId={branchId}&companyId={companyId}&userId={userId}',

  //Purchase Report Endpoints
  purchaseListURL: "Purchase?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}",
  purchaseDetailsURL: "PurchaseDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=purchaseId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  purchasePaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=PurchaseId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  purchaseExportURL:'Purchase',
  purchaseItemsExportURL: "PurchaseDetails",
  
  //Sale Report Endpoints
  salesReportListURL: 'Sale?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchType1={searchType1}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  salesReportDetailsURL: 'SaleDetails?pageNo={pageNo}&pageSize={pageSize}&reportType=SaleId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  saleExportURL:'Sale',
  
  //service Report Endpoints
  serviceReportListURL: 'Service?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  serviceReportDetailsURL: 'ServiceDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=ServiceId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  serviceExportURL: 'Service',

  //Purchase Return Report Endpoints
  purchaseReturnListURL: 'PurchaseReturn?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  purchaseReturnDetailsURL: 'PurchaseReturnDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=PurchaseReturnId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  purchaseReturnPaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=PurchaseReturnId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  purchaseReturnExportURL:'PurchaseReturn',

  //Sale Return Report Endpoints
  saleReturnListURL: 'SaleReturn?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  saleReturnDetailsURL: 'SaleReturnDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=SaleReturnId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  saleReturnPaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=SaleReturnId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  saleReturnExportURL:'SaleReturn',

  //Quotation Report Endpoints
  quotationListURL: 'Quotation?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  quotationDetailsURL: 'QuotationDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=QuotationId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  quotationPaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=QuotationId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  quotationExportURL: 'Quotation',

  //Quotation Request Issue Report Endpoints
  quotationRequestIssueListURL: 'QuotationRequestIssue?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  quotationRequestIssueDetailsURL: 'QuotationRequestIssueDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=QuotationId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  quotationRequestIssuePaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=QuotationId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  quotationRequestIssueExportURL: 'QuotationRequestIssue',

  //Quotation Request Received Report Endpoints
  quotationRequestReceivedListURL: 'QuotationRequestReceived?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  quotationRequestReceivedDetailsURL: 'QuotationRequestReceivedDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=QuotationId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  quotationRequestReceivedPaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=QuotationId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  quotationRequestReceivedExportURL: 'QuotationRequestReceived',

  //Purchase Order Report Endpoints
  purchaseOrderListURL: 'PurchaseOrder?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  purchaseOrderDetailsURL: 'PurchaseOrderDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=PurchaseOrderId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  purchaseOrderPaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=PurchaseOrderId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  purchaseOrderExportURL:'PurchaseOrder',

  //Sale Order Report Endpoints
  saleOrderListURL: 'SaleOrder?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  saleOrderDetailsURL: 'SaleOrderDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=SaleOrderId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  saleOrderPaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=SaleOrderId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  saleOrderExportURL:'SaleOrder',

  //Delivery Note Report Endpoints
  deliveryNoteListURL: 'DeliveryNote?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  deliveryNoteDetailsURL: 'DeliveryNoteDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=DeliveryNoteId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  deliveryNotePaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=DeliveryNoteId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  deliveryNoteExportURL:'DeliveryNote',

  //Delivery Note Receipt Report Endpoints
  deliveryNoteReceiptListURL: 'DeliveryNoteReceipt?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  deliveryNoteReceiptDetailsURL: 'DeliveryNoteReceiptDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=DeliveryNoteReceiptId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  deliveryNoteReceiptPaymentURL: "AccountVoucher?pageNo={pageNo}&pageSize={pageSize}&searchType=DeliveryNoteReceiptId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}",
  deliveryNoteReceiptExportURL:'DeliveryNoteReceipt',

  //Internal Stock Transfer Receipt Report Endpoints
  stockTransferListURL: 'InternalStockTransfer?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  stockTransferDetailsURL: 'InternalStockTransferDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=InternalStockTransferId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  stockTransferExportURL: 'InternalStockTransfer',

  //External Stock Transfer Receipt Report Endpoints
  externalStockTransferListURL: 'ExternalStockTransfer?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  externalStockTransferDetailsURL: 'ExternalStockTransferDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=ExternalStockTransferId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  externalStockTransferExportURL: 'ExternalStockTransfer',
  

  //Stock Adjustment Report Endpoints
  stockAdjustmentListURL: 'StockAdjustment?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  stockAdjustmentExportURL: 'StockAdjustment',

  //Stock Master Report Endpoints
  stockMasterListURL: 'StockMaster?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&searchQuery1={searchQuery1}&searchType2={searchType2}&sortType={sortType}&branchId={branchId}&companyId={companyId}',
  stockMasterDetailsURL: 'StockMasterDetails?pageNo={pageNo}&pageSize={pageSize}&searchType=StockMasterId&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}',
  stockMasterExportURL: 'StockMaster',

  //Stock Register Report Endpoints
  detailedStockReportURL: 'StockRegister?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&searchQuery1={searchQuery1}&searchType2={searchType2}&sortType={sortType}&branchId={branchId}&companyId={companyId}&startDate={startDate}&endDate={endDate}',
  stockRegisterReportExportURL: 'StockRegister',

  //Opening Stock Report Endpoint
  openingStockReportListURL: 'Item?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&searchQuery1={searchQuery1}&searchType2={searchType2}&sortType={sortType}&branchId={branchId}&companyId={companyId}',
  openingStockReportExportURL: 'Item',

  //Bill Wise Pending Purchase Report Endpoint
  billWisePendingPurchaseListURL: "Purchase?searchType2=pendingBills&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}",

  //Bill Wise Pending Sales Report Endpoints
  billWisePendingSalesReportListURL: 'Sale?searchType2=pendingBills&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchType1={searchType1}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',

  //Bill Wise Margin Report Endpoints
  billWiseMarginReportURL: 'BillWiseMargin?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  billWiseMarginReportExportURL: 'BillWiseMargin',

  // Adjustment Report Endpoint
  adjustmentReportURL: 'Adjustment?reportType={reportType}&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  adjustmentReportExportURL: 'Adjustment',

  // Cancelled transactions Report Endpoint
  cancelledTransactionsReportURL: 'cancelledTransaction?reportType={reportType}&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  cancelledTransactionsReportExportURL: 'cancelledTransaction',

  // Serial No Transactions Report Endpoint
  serialNoTransactionsReportURL: 'SerialNoTransaction?searchQuery={searchQuery}&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  
  //Sales Report Detailed List Endpoint
  salesReportDetailedListURL: 'SaleDetails?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&reportType=saleDetail&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  salesReportDetailedListExportURL: 'SaleDetails',

  //Monthly Sales analysis Endpoint
  monthlySalesAnalysisURL: 'MonthlyAnalysis?reportType=Sale&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  
  //Monthly Purchase analysis Endpoint
  monthlyPurchaseAnalysisURL: 'MonthlyAnalysis?reportType=Purchase&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  
  // Daily Sales Report Endpoint
  dailySalesReportListURL: 'DailySales?pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}&userId={userId}',
  
  // Daily Purchase Report Endpoint
  dailyPurchaseReportListURL:'DailySales?reportType=DailyPurchase&pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}&userId={userId}',
  
  // Service Expiry Report Endpoint
  serviceExpiryReportListURL: 'Service?reportType=expiryService&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  serviceExpiryReportExportURL: 'Service',

  // Item Expiry Report Endpoint
  itemExpiryReportListURL: 'ItemExpiry?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  itemExpiryReportExportURL: 'ItemExpiry',

  // Reorder Level Report Endpoint
  reorderLevelReportURL: 'StockLevel?reportType=Reorder&pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}&userId={userId}',
  stockLevelReportExportURL: 'StockLevel',

  // Maximum Stock Level Report Endpoint
  maximumStockLevelReportURL: 'StockLevel?reportType=Maximum&pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}&userId={userId}',

  // Minimum Stock Level Report Endpoint
  minimumStockLevelReportURL: 'StockLevel?reportType=Minimum&pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}&userId={userId}',

  // Forex Transactions Report Endpoint
  forexTransactionsReportURL: 'ForexTransaction?&pageNo={pageNo}&pageSize={pageSize}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',

  // Over Due Sales Invoice Report Endpoint
  overDueSalesInvoiceReportURL: 'OverDueSalesInvoices?&searchType={searchType}&searchQuery={searchQuery}&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  overDueSalesInvoiceReportExportURL: 'OverDueSalesInvoices',

  // Over Due Sales Invoice Report Endpoint
  overDuePurchaseInvoiceReportURL: 'OverDuePurchaseInvoice?&searchType={searchType}&searchQuery={searchQuery}&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  overDuePurchaseInvoiceReportExportURL: 'OverDuePurchaseInvoice',

  // Item wise Sales Order Summary Endpoint
  itemWiseSalesOrderSummaryURL: 'ItemWiseSaleOrder?&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&searchType2={searchType2}&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  itemWiseSalesOrderSummaryExportURL: 'ItemWiseSaleOrder',

  // Bank Reconciliation Endpoints
  bankReconciliationURL:'BankReconciliation?pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&reportType={reportType}&ledgerId={ledgerId}&startDate={startDate}&endDate={endDate}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}',

  // Salesman wise payment collection report Endpoint
  SalesmanWisePaymentCollectionReportURL: 'ExecutiveWisePayment?&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&searchQuery1={searchQuery1}&searchType2={searchType2}&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  SalesmanWisePaymentCollectionReportExportURL: 'ExecutiveWisePayment',
  
  //Creditors Report Endpoints
  creditorsReportURL: 'Vendor?reportType=creditor&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  creditorsReportExportURL: 'Vendor',

  //Debtors Report Endpoints
  debtorsReportURL: 'Customer?reportType=debtor&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  debtorsReportExportURL: 'Customer',

  //Age wise Debtors Report Endpoints
  ageWiseDebtorsReportURL: 'AgeWise?reportType=debtor&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  ageWiseReportExportURL: 'AgeWise',

  //Age wise Creditors Report Endpoints
  ageWiseCreditorsReportURL: 'AgeWise?reportType=creditor&pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',

  //PDC Report Endpoints
  pdcReportURL: 'PdcCheque?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&startDate={startDate}&endDate={endDate}&voucherDate={voucherDate}&sortType={sortType}&voucherType={voucherType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  pdcReportExportURL: 'PdcCheque',

  //PDC Calendar Endpoints
  pdcCalendarURL: 'PdcCalendar?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType=pdcCheque&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
 
  //Trial Balance Report 
  trialBalanceReportURL: 'TrailBalance?startDate={startDate}&endDate={endDate}&searchType={searchType}&branchId={branchId}&companyId={companyId}',
  
  //Chart of Accounts Endpoint
  chartOfAccountsURL: 'ChartOfAccounts?branchId={branchId}&companyId={companyId}&userId={userId}',
  
  //Item Wise Margin Report Endpoints
  itemWiseMarginReportURL: 'ItemMarginDetails?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  itemWiseMarginReportExportURL: 'ItemMarginDetails',

  //Item Wise Sales Margin Summary Report Endpoints
  itemWiseSalesMarginSummaryReportURL: 'ItemWiseSalesMarginSummary?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&sortType={sortType}&branchId={branchId}&companyId={companyId}&userId={userId}',
  itemWiseSalesMarginSummaryReportExportURL: 'ItemWiseSalesMarginSummary',

  //Item Wise Transactions Report Endpoints
  itemWiseTransactionsReportURL: 'ItemMargin?pageNo={pageNo}&pageSize={pageSize}&reportType={reportType}&searchType=itemSummary&searchQuery=&searchType1={searchType1}&searchQuery1={searchQuery1}&searchType2={searchType2}&searchQuery2={searchQuery2}&searchType3={searchType3}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',
  itemWiseTransactionsReportExportURL: 'ItemMargin',

  zReportListURL:'ZReport',

  workPeriodsURL:'WorkPeriod',

  transactionSummaryListURL: 'TransactionSummary?startDate={startDate}&endDate={endDate}&searchType={searchType}&searchQuery={searchQuery}&branchId={branchId}&companyId={companyId}&userId={userId}',

  //Item Master Report Endpoints
  itemMasterListURL: 'Item?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Category Master Report Endpoints
  categoryMasterListURL: 'Category?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Employee Master Report Endpoints
  employeeMasterListURL: 'Employee?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Stock Location Master Report Endpoints
  stockLocationMasterListURL: 'StockLocation?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Series Master Report Endpoints
  seriesMasterListURL: 'Series?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Currency Master Report Endpoints 
  currencyMasterListURL: 'Currency?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Account Group Master Report Endpoints
  accountGroupMasterListURL: 'AccountGroup?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Ledger Master Report Endpoints
  ledgerMasterListURL: 'Ledger?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Customer Master Report Endpoints
  customerMasterListURL: 'Customer?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Vendor Master Report Endpoints
  vendorMasterListURL: 'Vendor?pageNo={pageNo}&pageSize={pageSize}&desc={Desc}&searchType={searchType}&searchQuery={searchQuery}&sortType={sortType}&branchId={branchId}&companyId={companyId}',

  //Day Book Report Endpoints
  daybookListURL:'DayBook?pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',

  //Ledger Book Report Endpoints
  ledgerBookListURL:'LedgerBook?pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&ledgerId={ledgerId}&startDate={startDate}&endDate={endDate}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}',

  //Cash Book Report Endpoints
  cashBookListURL:'CashBook?pageNo={pageNo}&pageSize={pageSize}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',
  cashBookExportURL: 'CashBook',

  //Bank Book Report Endpoints
  bankBookListURL:'BankBook?pageNo={pageNo}&pageSize={pageSize}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',
  bankBookExportURL:'BankBook',

  //Voucher Report Endpoints
  voucherReportListURL:'Voucher?reportType={reportType}&searchType={searchType}&searchQuery={searchQuery}&pageNo={pageNo}&pageSize={pageSize}&startDate={startDate}&endDate={endDate}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}&userId={userId}',
  voucherReportExportURL: 'Voucher',

  //Input Tax Report Endpoints
  inputTaxReportListURL:'Tax?reportType=InputTax&searchType={searchType}&searchQuery={searchQuery}&pageNo={pageNo}&pageSize={pageSize}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',

  //Output Tax Report Endpoints
  outputTaxReportListURL:'Tax?reportType=OutputTax&searchType={searchType}&searchQuery={searchQuery}&pageNo={pageNo}&pageSize={pageSize}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',
  taxReportExportURL: 'Tax',

  //Vat Summary Report Endpoints
  vatSummaryReportListURL:'Vat?pageNo={pageNo}&pageSize={pageSize}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',

  //Profit and Loss Endpoints
  profitAndLossListURL:'ProfitAndLoss?startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}',

  //Hold List EndPoints
  holdListURL:'Hold?pageNo={pageNo}&pageSize={pageSize}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}',

  //Balance Sheet Endpoints
  balanceSheetURL:'BalanceSheet?startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}',
  
  //Get Item Wise Stock Report
  itemWiseStockReportURL:'StockRegisterDetails?pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&itemId={itemId}&startDate={startDate}&endDate={endDate}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}',
  
  //Statement Of Accounts Endpoints
  statementOfAccountsURL:'StatementOfAccounts?pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&searchType1={searchType1}&ledgerId={ledgerId}&startDate={startDate}&endDate={endDate}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}',

  //Account group Report Endpoints
  accountGroupReportListURL:'AccountGroups?pageNo={pageNo}&pageSize={pageSize}&searchType={searchType}&searchQuery={searchQuery}&startDate={startDate}&endDate={endDate}&branchId={branchId}&companyId={companyId}&userId={userId}',
  accountGroupReportExportURL: 'AccountGroups',

  forexCurrencyAdjustmentURL: 'ForexCurrencyAdjustment?startDate={startDate}&endDate={endDate}&pageNo={pageNo}&pageSize={pageSize}&sortType={sortType}&desc={Desc}&branchId={branchId}&companyId={companyId}',
  actionHandler,
};